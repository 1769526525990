module.exports = [{
      plugin: require('/home/gitlab-runner/builds/64d18060/0/marketing_retail/tas_agent_landing/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"468887813495212"},
    },{
      plugin: require('/home/gitlab-runner/builds/64d18060/0/marketing_retail/tas_agent_landing/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NDL8ZTZ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/home/gitlab-runner/builds/64d18060/0/marketing_retail/tas_agent_landing/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Продаєш авто? Продай швидше і зароби більше!💸 | ТАСКОМБАНК\"","short_name":"Продай швидше і зароби більше!💸 | ТАСКОМБАНК\"","start_url":"/","background_color":"#31479a","theme_color":"#31479a","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('/home/gitlab-runner/builds/64d18060/0/marketing_retail/tas_agent_landing/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gitlab-runner/builds/64d18060/0/marketing_retail/tas_agent_landing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
